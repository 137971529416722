.hp-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    flex-grow: 1;
    min-height: calc(100vh - 152px);
    padding-bottom: 60px;
    color: black;
}

.hp-inner {
    background-color: white;
    width: 1080px;
}

.school-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #AAAAAA;
    max-width: 300px;
}

.school-card img {
    width: 260px;
    max-height: 100px;
}

.school-card:hover {
    box-shadow: 1px 1px 4px 1px #AAAAAA;
}

.disabled {
    box-shadow: none !important;
}

.school-card h1 {
    text-decoration: none;
    color: black;
    font-size: 1.5em;
    text-align: center;
}

a {
    text-decoration: none !important; 
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    margin-top: 20px;
    place-items: stretch;
}

.info h2 {
    margin-top: 24px;
    margin-bottom: 4px;;
}

.line {
    border-top: 1px solid #AAAAAA;
    margin-top: 20px;
    margin-bottom: 40px;
}

.num-reviews {
    text-decoration: none;
    color: black;
    font-size: 16px;
    text-align: center;
    margin-top: 8px;
    font-weight: 500;
}

@media (max-width: 480px) {
    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 18px;
    }

    .school-card h1 {
        font-size: 20px;
    }
}

@media (max-width: 390px) {
    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 16px;
    }
}